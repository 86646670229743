import { ReactNode } from 'react'
import classNames from 'classnames'
import HeroLogo, { HeroLogoType } from './HeroLogo'
import ReviewsIO from 'components/ReviewsIO'
import { ReactFC } from 'common/types/ReactFC'
import Strings from 'project/localisation'
import { ColorType } from 'common/types/color-type'
import imageResize from 'project/image-proxy-web'

type HeroType = {
  title: ReactNode
  background: ColorType
  subtitle: string
  logoColor?: HeroLogoType['color']
  hideReviews?: boolean
  isRightCTA?: boolean
  theme?: 'light' | 'dark'
  cta?: ReactNode
  image: string
  className?: string
}

const HeroHome: ReactFC<HeroType> = ({
  background,
  className,
  cta,
  hideReviews,
  image,
  isRightCTA,
  logoColor = 'white',
  subtitle,
  theme = 'dark',
  title,
}) => {
  return (
    <>
      <div
        className={classNames('hero', `bg-${background}`, className, {
          'color-white': theme === 'light',
        })}
      >
        <div className='container mb-1 px-xxl-16'>
          <div className='row align-items-center'>
            <div className={'col-md-8'}>
              <div className='text-center text-md-start'>
                <HeroLogo
                  className='d-none d-lg-block mb-3'
                  color={logoColor}
                />
                <h1
                  dangerouslySetInnerHTML={{ __html: `${title}` }}
                  className='h1'
                />
                <h2
                  className='hero__sub-heading'
                  dangerouslySetInnerHTML={{ __html: `${subtitle}` }}
                />
              </div>

              {isRightCTA ? (
                <div className='offset-6 offset-md-0 text-center text-md-start'>
                  {cta}
                  {!hideReviews && <ReviewsIO />}
                </div>
              ) : (
                <div className='text-center text-md-start'>
                  {cta}
                  {!hideReviews && <ReviewsIO />}
                </div>
              )}
            </div>
            <div className='col-md-4'>
              {image && (
                <img
                  loading='eager'
                  className='hero__image'
                  alt={`${title} ${Strings.logo}`}
                  src={imageResize(image)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroHome
