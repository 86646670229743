import React, { FC } from 'react'
import { officeStrings } from 'common/strings/office-strings'
import Heading from 'components/Heading'
import Button from 'components/forms/Button'
import Link from 'next/link'
import HeadingWithSpan from 'components/HeadingWithSpan'
import classNames from 'classnames'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type OfficeSectionType = {
  option?: 1 | 2
  classesForTitle?: string
  title?: string
}

const OfficeSection: FC<OfficeSectionType> = ({
  option = 1,
  classesForTitle = option === 1
    ? 'mb-lg-10 mb-7'
    : 'pt-lg-5 pt-7 mb-lg-10 mb-8',
  title = officeStrings.ourOffice,
}) => {
  const size = useWindowSize()
  return (
    <>
      <h3
        dangerouslySetInnerHTML={{ __html: title }}
        className={classNames('text-semibold h2 text-center', classesForTitle)}
      />
      <div className='mx-md-n12px rounded-box overflow-hidden'>
        <img
          className='img-fluid'
          width={1380}
          height={640}
          loading='lazy'
          alt={'Hoxton Mix Office'}
          src={imageResize(
            require('public/images/office/homepage-office-image-desktop.jpg'),
            1380,
            640,
          )}
        />
      </div>
      <div className='row mt-md-6'>
        <div
          className={classNames(
            'col-lg-6 text-center py-xl-6 px-xl-8 px-2',
            option === 1 ? 'py-4' : 'py-lg-4 pt-8 pb-6',
          )}
        >
          <Heading
            size={2}
            className={classNames(
              'h2 text-semibold mb-md-5',
              option === 1 ? 'mb-3' : 'mb-4',
            )}
          >
            {officeStrings.privateOfficesTitle}
          </Heading>
          <p
            className={classNames(
              'lead-large text-semibold',
              option === 1 ? 'mb-2' : 'mb-lg-2 mb-4',
            )}
          >
            {officeStrings.privateOfficesSubtitle}
          </p>
          <div
            className={classNames({ 'px-lg-0 px-5': option === 2 })}
            dangerouslySetInnerHTML={{
              __html: officeStrings.privateOfficesDescription,
            }}
          />
          {!size.isMdOrLower && (
            <Link className='a-unstyled' href='/paul-street-office'>
              <Button
                size='large'
                className='lead d-md-block d-none mx-auto'
                iconRight='chevron-right'
                iconRightColour='#0071E3'
                theme='text'
              >
                {officeStrings.checkOutPaulStreet}
              </Button>
            </Link>
          )}
          {size.isMdOrLower && (
            <Link className='a-unstyled' href='/paul-street-office'>
              <Button
                size='small'
                className={classNames('d-md-none mx-auto', {
                  'text-xl': option === 2,
                })}
                iconRight='chevron-right'
                iconRightColour='#0071E3'
                iconWidth={10}
                iconHeight={15}
                theme='text'
              >
                {officeStrings.checkOutPaulStreet}
              </Button>
            </Link>
          )}
        </div>
        <div className='col-lg-6 text-center py-xl-6 px-xl-5 py-md-4 pb-4 px-4'>
          <HeadingWithSpan
            size={2}
            className={classNames(
              'h2 text-semibold mb-md-5',
              option === 1 ? 'mb-3' : 'mb-4',
            )}
          >
            {officeStrings.luxuryWorkplace}
          </HeadingWithSpan>
          <p
            className={classNames(
              'lead-large text-semibold',
              option === 1 ? 'mb-md-2 mb-3' : 'mb-lg-2 mb-4',
            )}
          >
            {officeStrings.luxuryWorkplaceSubtitle}
          </p>
          <div
            className={classNames({ 'px-lg-0 px-3': option === 2 })}
            dangerouslySetInnerHTML={{
              __html: officeStrings.luxuryWorkplaceDescription,
            }}
          />
          {((!size.isMdOrLower && option === 1) || option === 2) && (
            <p
              className={classNames('lead-large text-semibold', {
                'mt-7 mb-0 px-5': size.isSmOrLower && option === 2,
              })}
            >
              {officeStrings.luxuryWorkplaceDescription2}
            </p>
          )}
          {size.isMdOrLower && option === 1 && (
            <Link className='a-unstyled' href='/paul-street-office'>
              <Button
                size='small'
                className='mx-auto'
                iconRight='chevron-right'
                iconRightColour='#0071E3'
                iconWidth={10}
                iconHeight={15}
                theme='text'
              >
                {officeStrings.checkOutPaulStreet}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

export default OfficeSection
