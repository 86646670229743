import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  checkOutPaulStreet: 'Check out Paul Street',
  luxuryWorkplace:
    '<span class="d-block">A luxury workplace</span> <span class="d-block">tailored to your needs</span>',
  luxuryWorkplaceDescription: `
    <p class="lead mb-md-8 mb-3">
    A modern and contemporary office fully renovated for the Hoxton Mix. The office is located in the heart of Old Street, dubbed the silicon roundabout, well known for being the centre of cutting-edge development in the digital sector.
</p>
    `,
  luxuryWorkplaceDescription2:
    'It’s 2 minutes walk from Old Street tube station',
  luxuryWorkplaceSubtitle: 'Silicon roundabout',
  ourOffice: 'The Office.',
  privateOfficesDescription: `
    <p class="lead mb-md-8 mb-6">
    Located in the heart of Shoreditch commuting couldn’t be easier with nearby Old Street and Liverpool Street Stations.
</p>
<p class="lead">
     Whether you need a single desk or private office tech startups, marketing & digital agencies appreciate this location for its mix of dining and nightlife hot-spots and visitor appeal.
</p>
    `,
  privateOfficesSubtitle: 'Located in the heart of Shoreditch',
  privateOfficesTitle: 'Private Offices, Co-working and Meeting rooms',
}
export const officeStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
