import Card from './base/Card'
import { ReactFC } from 'common/types/ReactFC'
import React from 'react'
import { addressServicesStrings } from 'common/strings/address-services-strings'
import imageResize from 'project/image-proxy-web' // we need this to make JSX compile

type AddressServicesCardType = {}

const AddressServicesCard: ReactFC<AddressServicesCardType> = ({}) => {
  return (
    <div className='col-lg-6'>
      <Card theme='gradient-fuchsia' className='plug-card' padding='lg' symbol>
        <div className='my-auto'>
          <img
            loading='lazy'
            className='plug-card__icon img-fluid mb-sm-0 mb-5'
            alt={addressServicesStrings.icon}
            src={imageResize(
              require('public/images/icons/homepage-hm-symbol-desktop.svg'),
            )}
          />
          <h2 className='display-1 text-light text-semibold mb-md-4 md-0'>
            {addressServicesStrings.addressServicesForBusiness}
          </h2>
          <div
            className='color-white lead-large-desktop'
            dangerouslySetInnerHTML={{
              __html: addressServicesStrings.addressServicesDescription,
            }}
          />
        </div>
      </Card>
    </div>
  )
}

export default AddressServicesCard
