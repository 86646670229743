import React from 'react'
import Strings from 'project/localisation'
import ImageCard from './base/ImageCard'
import Button from 'components/forms/Button'
import Link from 'next/link'
import { ReactFC } from 'common/types/ReactFC'
import { tenMinutesOrLessStrings } from 'common/strings/ten-minutes-or-less-strings'
import RobotHeadCard from './base/RobotHeadCard'
import { ColorType } from 'common/types/color-type'
import useWindowSize from 'components/util/useWindowSize'
import classNames from 'classnames'
import classnames from 'classnames'
import imageResize from 'project/image-proxy-web'

type VirtualOfficePlansCardType = {
  condensed?: boolean
  theme?: ColorType
  robotHeadCardOption?: number
  tenMinutesOrLessCardIsLarge?: boolean
}

const VirtualOfficePlansCard: ReactFC<VirtualOfficePlansCardType> = ({
  condensed,
  robotHeadCardOption = 1,
  tenMinutesOrLessCardIsLarge = false,
  theme,
}) => {
  const size = useWindowSize()
  return condensed ? (
    <RobotHeadCard
      theme={theme}
      cardOption={robotHeadCardOption}
      padding='0'
      footer={
        robotHeadCardOption === 1 ? (
          <span className='display-1 text-bold'>{`${Strings.minutesOrLess}`}</span>
        ) : null
      }
      className={classNames(
        `ten-minutes-card ten-minutes-card--option${robotHeadCardOption} color-white d-flex align-items-center`,
        robotHeadCardOption === 1 ? 'py-sm-10 py-7' : 'py-sm-6 py-4',
        tenMinutesOrLessCardIsLarge
          ? 'ten-minutes-card--large'
          : 'ten-minutes-card--default',
      )}
      titleClass={classnames(
        'ten-minutes-text',
        `ten-minutes-text--option${robotHeadCardOption}`,
      )}
    >
      10
    </RobotHeadCard>
  ) : (
    <ImageCard
      gutters='g-0'
      padding='0'
      textAlign='center'
      verticalAlign='center'
      imageAlt={`${tenMinutesOrLessStrings.tenMinsOrLess} icon`}
      image={require('public/images/plans/homepage-vo-in-10-desktop.png')}
      className='px-xl-17 px-sm-5'
      classNameForCol1='col-lg-7 py-8 px-md-5 px-3'
      classNameForCol2='col-lg-5 d-lg-block d-none my-auto text-center'
    >
      <img
        loading='lazy'
        className='img-fluid mb-2 mb-4 envelope-icon'
        width={84}
        height={84}
        alt={'Envelope Icon'}
        src={imageResize(
          require('public/images/icons/homepage-vo-envelope-icon.svg'),
        )}
      />
      <h3
        className='text-semibold h2 mb-sm-4 mb-0'
        dangerouslySetInnerHTML={{
          __html: `${tenMinutesOrLessStrings.tenMinsOrLess}`,
        }}
      />
      {!size.isLgOrHigher && (
        <img
          loading='lazy'
          className='img-fluid max-w-lg-sm-50'
          alt={`${tenMinutesOrLessStrings.tenMinsOrLess} icon`}
          src={imageResize(
            require('public/images/plans/homepage-vo-in-10-desktop.png'),
          )}
        />
      )}
      <section
        className='text-center lead mt-sm-0 mt-n6'
        dangerouslySetInnerHTML={{
          __html: `${tenMinutesOrLessStrings.tenMinsOrLessDescription}`,
        }}
      />
      <Link className='a-unstyled' href='/virtual-office-london-old-street'>
        <Button className='mt-2 mt-lg-7' theme='secondary'>
          {Strings.viewPlans}
        </Button>
      </Link>
    </ImageCard>
  )
}

export default VirtualOfficePlansCard
