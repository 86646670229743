import React, { FC } from 'react'
import Accordion from './Accordion'
import Heading from './Heading'
import { plansDesksOfficesStrings } from 'common/strings/plans-desks-offices-strings' // we need this to make JSX compile

type PlansDesksOfficesType = {
  className?: string
}

function body() {
  return [
    {
      body: (
        <div
          dangerouslySetInnerHTML={{
            __html: `${plansDesksOfficesStrings.virtualOfficePlansContent}`,
          }}
        />
      ),
      title: `${plansDesksOfficesStrings.virtualOfficePlansTitle}`,
    },
    {
      body: (
        <div
          dangerouslySetInnerHTML={{
            __html: `${plansDesksOfficesStrings.dedicatedDeskContent}`,
          }}
        />
      ),
      title: `${plansDesksOfficesStrings.dedicatedDeskTitle}`,
    },
    {
      body: (
        <div
          dangerouslySetInnerHTML={{
            __html: `${plansDesksOfficesStrings.privateOfficeSuitsContent}`,
          }}
        />
      ),
      title: `${plansDesksOfficesStrings.privateOfficeSuitsTitle}`,
    },
  ]
}

const PlansDesksOffices: FC<PlansDesksOfficesType> = ({
  className = 'mt-md-17 mb-md-13 mt-8 mb-4',
}) => {
  return (
    <div className={className}>
      <Heading className='text-center h2 text-semibold' size={3}>
        {plansDesksOfficesStrings.plansDesksOffices}
      </Heading>
      <div className='d-flex justify-content-md-center'>
        <div className='col-lg-9 col-12 my-5 px-md-5 px-2'>
          <Accordion chevronColour='#86868B' items={body()} />
        </div>
      </div>
    </div>
  )
}

export default PlansDesksOffices
