import React from 'react'
import Card from './base/Card'
import { ReactFC } from 'common/types/ReactFC'
import { mailHandlingStrings } from 'common/strings/mail-handling-strings'
import imageResize from 'project/image-proxy-web'

type MailHandlingCardType = {}

const MailHandlingCard: ReactFC<MailHandlingCardType> = ({}) => {
  return (
    <div className='col-lg-6'>
      <Card
        className='text-center mail-card pt-xxl-5 pt-7 px-xxl-9 px-3'
        padding='lg'
      >
        <h3
          className='h2 text-semibold'
          dangerouslySetInnerHTML={{
            __html: `${mailHandlingStrings.mailHandling}`,
          }}
        />
        <section
          className='lead mt-sm-3 mt-2'
          dangerouslySetInnerHTML={{
            __html: `${mailHandlingStrings.mailHandlingDescription}`,
          }}
        />
        <img
          loading='lazy'
          className='mail-card__image img-fluid'
          alt={`${mailHandlingStrings.mailHandling} logo`}
          src={imageResize(
            require('public/images/plans/homepage-plugz-hand-letters-desktop.png'),
          )}
        />
      </Card>
    </div>
  )
}

export default MailHandlingCard
