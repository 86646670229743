import { ItemListElements } from 'next-seo/lib/types'
import { seoStrings } from 'common/strings/seo-strings'
import Strings from 'project/localisation'

export const homeBreadcrumb: () => ItemListElements[] = () => [
  {
    item: 'https://www.hoxtonmix.com/',
    name: seoStrings.breadcrumbHome,
    position: 1,
  },
]
export const blogBreadcrumb: () => ItemListElements[] = () => [
  homeBreadcrumb()[0],
  {
    item: 'https://www.hoxtonmix.com/blog/',
    name: `${Strings.blog}`,
    position: 2,
  },
]

export const virtualOfficeOldStreetBreadcrumb: () => ItemListElements[] =
  () => [
    homeBreadcrumb()[0],
    {
      item: 'https://www.hoxtonmix.com/virtual-office-london-old-street/',
      name: `${Strings.virtualOffice}`,
      position: 2,
    },
  ]

export const checkoutBreadcrumb: () => ItemListElements[] = () => [
  homeBreadcrumb()[0],
  {
    item: 'https://www.hoxtonmix.com/checkout/',
    name: `${Strings.checkout}`,
    position: 2,
  },
]
