import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultStrings = {
  how: 'How',
  howDescription: `<span class="d-xxl-block d-lg-inline d-block">The way we do this is by taking a</span> <span class="d-xxl-block d-lg-inline d-block">technology-first approach to B2B products</span> <span class="d-xxl-block d-lg-inline d-block">and services, helping SMEs anywhere to</span> <span class="d-xxl-block d-lg-inline d-block">have a presence in London and manage</span> <span class="d-xxl-block d-lg-inline d-block">their business remotely.</span>`,
  what: 'What',
  whatDescription: `<p class="mb-md-5 mb-2"><span class="d-xxl-block d-lg-inline d-block">We provide virtual office solutions</span> <span class="d-xxl-block d-lg-inline d-block">that add value to the business.</span></p><p><strong><span class="d-xxl-block d-lg-inline d-block">Used by 60,000+ businesses.</span> <span class="d-xxl-block d-lg-inline d-block">Small to enterprise. Globally.</span></strong></strong></p>`,
  why: 'Why',
  whyDescription: `<span class="d-xxl-block d-lg-inline d-block">We’re passionate about entrepreneurship</span> <span class="d-xxl-block d-lg-inline d-block">and helping businesses thrive. Everything we</span> <span class="d-xxl-block d-lg-inline d-block">do is to make it easier to start a business</span> <span class="d-xxl-block d-lg-inline d-block">and provide you with the tools to succeed.</span>`,
}
export const whatHowWhyStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
