import ImageCard from './base/ImageCard'
import Strings from 'project/localisation'
import Link from 'next/link'
import Button from 'components/forms/Button'
import { ReactFC } from 'common/types/ReactFC'
import { companyFormationStrings } from 'common/strings/company-formation-strings'
import React from 'react'
import useWindowSize from 'components/util/useWindowSize'
import HeadingWithSpan from 'components/HeadingWithSpan'
import imageResize from 'project/image-proxy-web'

type CompanyFormationCardType = {}
const CompanyFormationCard: ReactFC<CompanyFormationCardType> = ({}) => {
  const size = useWindowSize()
  return (
    <ImageCard
      gutters='g-0'
      padding='0'
      textAlign='center'
      verticalAlign='center'
      imageAlt={`${Strings.companyFormation}`}
      image={require('public/images/plans/homepage-hoxton-crunch-desktop.png')}
      imageClass={'max-w-lg-sm-50'}
      className='ps-xl-16 pe-xl-13 px-sm-5 mt-10 mb-lg-17 mb-8'
      classNameForCol1='col-lg-5 py-6'
      classNameForCol2='col-lg-7 my-auto pe-xxl-5'
    >
      {size.isLgOrHigher && (
        <img
          loading='lazy'
          className='img-fluid'
          width={394}
          height={82}
          alt={'Hoxton Mix and Crunch'}
          src={imageResize(
            require('public/images/logos/homepage-hoxton-crunch-logos-desktop.svg'),
          )}
        />
      )}
      {!size.isLgOrHigher && (
        <div className='mb-4'>
          <img
            loading='lazy'
            className='img-fluid'
            width={150}
            alt={'Hoxton Mix'}
            src={imageResize(require('public/images/logos/hm-logo-black.svg'))}
          />
          <div className='plus-icon mx-auto my-2'></div>
          <img
            loading='lazy'
            className='img-fluid'
            alt={'Crunch'}
            src={imageResize(
              require('public/images/logos/Crunch-logo-strapline-pink.svg'),
            )}
          />
        </div>
      )}
      <HeadingWithSpan size={2} className='h2 text-semibold mt-md-3 mt-1'>
        {companyFormationStrings.cardTitle}
      </HeadingWithSpan>
      <div
        className='mt-md-4 mt-2'
        dangerouslySetInnerHTML={{
          __html: companyFormationStrings.cardDescription,
        }}
      />
      <Link className='a-unstyled' href={'/company-formation'}>
        <Button className='mt-lg-4 mt-1' theme='secondary'>
          {Strings.findOutMore}
        </Button>
      </Link>
    </ImageCard>
  )
}

export default CompanyFormationCard
