import { whatHowWhyStrings } from 'common/strings/what-how-why-strings'
import React from 'react'
import Card from './base/Card'
import { ReactFC } from 'common/types/ReactFC'
import imageResize from 'project/image-proxy-web'

type MailHandlingCardType = {}

const MailHandlingCard: ReactFC<MailHandlingCardType> = ({}) => {
  const sections = [
    {
      description: whatHowWhyStrings.whyDescription,
      img: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 41.486 38.774'
          width={41.89}
          height={38.77}
        >
          <clipPath id='a'>
            <path d='M0 0h41.486v38.774H0z' />
          </clipPath>
          <g stroke='#1d1d1f' clipPath='url(#a)'>
            <g fill='#f8f9fa'>
              <path d='M11.656 26.364a8.6 8.6 0 0 0-1.322-3.746 6.332 6.332 0 0 1-.992-3.418l-.015-9.912L24.41 4.456l3.69 2.959-1.546 10.852-1.981 4.242.329 3.855z' />
              <path
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                d='M11.656 26.364a8.6 8.6 0 0 0-1.322-3.746 6.332 6.332 0 0 1-.992-3.418l-.015-9.912L24.41 4.456l3.69 2.959-1.546 10.852-1.981 4.242.329 3.855z'
              />
              <path d='M12.327 12.483h-.987a2.028 2.028 0 0 1-2.022-2.022V2.772A2.028 2.028 0 0 1 11.34.75h.987a2.028 2.028 0 0 1 2.022 2.022v7.689a2.028 2.028 0 0 1-2.022 2.022' />
              <rect
                width='5.031'
                height='11.733'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                rx='2.022'
                transform='translate(9.318 .75)'
              />
              <path d='M17.358 12.483h-.987a2.028 2.028 0 0 1-2.022-2.022V2.772A2.028 2.028 0 0 1 16.371.75h.987a2.028 2.028 0 0 1 2.022 2.022v7.689a2.028 2.028 0 0 1-2.022 2.022' />
              <rect
                width='5.031'
                height='11.733'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                rx='2.022'
                transform='translate(14.349 .75)'
              />
              <path d='M22.389 12.483H21.4a2.028 2.028 0 0 1-2.022-2.022V2.772A2.028 2.028 0 0 1 21.4.75h.987a2.028 2.028 0 0 1 2.022 2.022v7.689a2.028 2.028 0 0 1-2.022 2.022' />
              <rect
                width='5.031'
                height='11.733'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                rx='2.022'
                transform='translate(19.38 .75)'
              />
              <path d='M23.291 8.777h-5.022a1.936 1.936 0 0 1-1.93-1.93v-.461a1.936 1.936 0 0 1 1.93-1.93h8.591a1.936 1.936 0 0 1 1.93 1.93s-.252 13.2-2.18 15.131a6.8 6.8 0 0 0-1.71 4.847l-5.76-4.65a11.385 11.385 0 0 1 4.135-9.506s.234-3.431.014-3.431' />
              <path
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                d='M23.291 8.777h-5.022a1.936 1.936 0 0 1-1.93-1.93v-.461a1.936 1.936 0 0 1 1.93-1.93h8.591a1.936 1.936 0 0 1 1.93 1.93s-.252 13.2-2.18 15.131a6.8 6.8 0 0 0-1.71 4.847l-5.76-4.65a11.385 11.385 0 0 1 4.135-9.506s.236-3.431.016-3.431z'
              />
              <path d='M25.2 30.5H11.108a.622.622 0 0 1-.621-.621v-2.894a.622.622 0 0 1 .621-.621H25.2a.622.622 0 0 1 .621.621v2.889a.622.622 0 0 1-.621.621' />
              <rect
                width='15.333'
                height='4.131'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                rx='.621'
                transform='translate(10.487 26.364)'
              />
              <path d='M26.638 38.024H9.669a.932.932 0 0 1-.929-.924v-5.676a.932.932 0 0 1 .929-.929h16.969a.932.932 0 0 1 .929.929V37.1a.932.932 0 0 1-.929.929' />
              <rect
                width='18.827'
                height='7.529'
                strokeLinecap='round'
                strokeMiterlimit='10'
                strokeWidth='1.5'
                rx='.929'
                transform='translate(8.74 30.495)'
              />
            </g>
            <path
              fill='none'
              strokeLinecap='round'
              strokeMiterlimit='10'
              d='M2.758 3.119v4.572m2.314-2.286H.5m33.601 5.433v4.572m2.313-2.286h-4.572m6.831 6.327v4.572m2.313-2.286h-4.572'
            />
            <path
              fill='#f8f9fa'
              d='M5.878 21.445 4.3 20.8l-1.43.938.125-1.706-1.334-1.07 1.661-.408.605-1.6.9 1.453 1.709.082-1.1 1.306z'
            />
            <path
              fill='#f8f9fa'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M5.878 21.445 4.3 20.8l-1.43.938.125-1.706-1.334-1.07 1.661-.408.605-1.6.9 1.453 1.709.082-1.1 1.306z'
            />
            <path
              fill='#f8f9fa'
              d='m37.662 6.132-1.583-.646-1.43.938.125-1.706-1.334-1.07 1.66-.408.6-1.6.9 1.453 1.709.082-1.1 1.306z'
            />
            <path
              fill='#f8f9fa'
              strokeLinecap='round'
              strokeLinejoin='round'
              d='m37.662 6.132-1.583-.646-1.43.938.125-1.706-1.334-1.07 1.66-.408.6-1.6.9 1.453 1.709.082-1.1 1.306z'
            />
          </g>
        </svg>
      ),
      title: whatHowWhyStrings.why,
    },
    {
      description: whatHowWhyStrings.howDescription,
      img: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 47.977 38.558'
          width={41.89}
          height={38.77}
        >
          <g>
            <path
              fill='none'
              stroke='#1d1d1f'
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='2.077'
              d='M41.8 24.694V3.651a2.613 2.613 0 0 0-2.612-2.613H3.65a2.613 2.613 0 0 0-2.612 2.613v26.118a2.612 2.612 0 0 0 2.612 2.612h30.618'
            />
            <path
              fill='#1d1d1f'
              fillRule='evenodd'
              d='m47.945 34.06-.613 1.474a.393.393 0 0 1-.513.215l-.7-.288A.958.958 0 0 0 44.88 36.7l.289.694a.392.392 0 0 1-.213.515l-1.475.612a.392.392 0 0 1-.513-.213l-.289-.7a.96.96 0 0 0-1.76 0l-.288.7a.389.389 0 0 1-.514.213l-1.474-.61a.4.4 0 0 1-.214-.514l.288-.695a.958.958 0 0 0-1.243-1.242l-.7.287a.388.388 0 0 1-.513-.213l-.61-1.478a.393.393 0 0 1 .213-.515l.7-.288a.956.956 0 0 0 0-1.755l-.7-.289a.391.391 0 0 1-.213-.515l.612-1.474a.394.394 0 0 1 .513-.216l.695.288a.958.958 0 0 0 1.243-1.243l-.287-.695a.389.389 0 0 1 .213-.514l1.474-.61a.393.393 0 0 1 .513.213l.289.7a.959.959 0 0 0 1.76 0l.288-.7a.39.39 0 0 1 .514-.213l1.474.61a.394.394 0 0 1 .214.514l-.285.691A.958.958 0 0 0 46.13 29.3l.691-.286a.389.389 0 0 1 .514.213l.61 1.477a.394.394 0 0 1-.214.515l-.7.289a.956.956 0 0 0 0 1.755l.7.288a.39.39 0 0 1 .213.514zM41.8 29.292a3.09 3.09 0 1 0 2.183.905 3.087 3.087 0 0 0-2.183-.905m0 4.632a1.543 1.543 0 1 1 1.092-.452 1.544 1.544 0 0 1-1.092.452'
            />
            <path
              fill='#1d1d1f'
              d='M1.231 8.11h40.376V1.239H1.231zm32.1-2.959a.7.7 0 0 1-.7.709h-.936a.7.7 0 0 1-.7-.709V4.2a.7.7 0 0 1 .7-.709h.936a.7.7 0 0 1 .7.709zm3.623 0a.7.7 0 0 1-.7.709h-.936a.7.7 0 0 1-.7-.709V4.2a.7.7 0 0 1 .7-.709h.936a.7.7 0 0 1 .7.709zm3.624 0a.7.7 0 0 1-.7.709h-.936a.7.7 0 0 1-.7-.709V4.2a.7.7 0 0 1 .7-.709h.936a.7.7 0 0 1 .7.709z'
            />
            <path
              fill='none'
              stroke='#1d1d1f'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m25.514 16.755 6.335 3.14-6.335 2.892m-7.269 0-6.335-3.14 6.335-2.892m5.7-3.415-4.131 12.724'
            />
          </g>
        </svg>
      ),
      title: whatHowWhyStrings.how,
    },
    {
      description: whatHowWhyStrings.whatDescription,
      img: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 42.729 39.92'
          width={41.89}
          height={38.77}
        >
          <g fill='none' stroke='#1d1d1f' strokeLinecap='round'>
            <path
              strokeMiterlimit='10'
              strokeWidth='1.967'
              d='M22.521 7.594H8.144a2.825 2.825 0 0 0-2.974 2.974v19.61h21.152m3.178 0h2.056m2.753 0h3.525V22.3M5.17 30.178l-4.076 6.5a1.624 1.624 0 0 0 1.762 2.258h20.877'
            />
            <path
              strokeMiterlimit='10'
              strokeWidth='1.967'
              d='m37.862 30.15 3.773 6.527a1.625 1.625 0 0 1-1.763 2.259H23.733M25.689.984H35.92a.475.475 0 0 1 .475.475v16.456a.475.475 0 0 1-.475.475H25.689a.476.476 0 0 1-.476-.476V1.46a.476.476 0 0 1 .476-.476z'
            />
            <g strokeLinejoin='round'>
              <path strokeWidth='1.967' d='M39.1 6.767h1.873V18.39H34.31' />
              <path
                strokeWidth='2.271'
                d='M30.025 16.697h1.557v1.496h-1.557z'
              />
              <path
                strokeWidth='1.418'
                d='M28.511 4.412h.953v.953h-.953zm3.897 0h.953v.953h-.953zm-3.897 3.953h.953v.953h-.953zm3.897 0h.953v.953h-.953zm-3.897 3.953h.953v.953h-.953zm3.897 0h.953v.953h-.953z'
              />
            </g>
          </g>
        </svg>
      ),
      title: whatHowWhyStrings.what,
    },
  ]

  return (
    <>
      <div className='row mt-md-6 mb-lg-11 mb-5 pt-4 pb-4 gx-6 gy-4'>
        {sections.map(({ description, img, title }) => (
          <div key={title} className='col-lg-4'>
            <Card
              radius='md'
              padding='sm'
              className='text-center align-items-center card__min-height-256px'
            >
              {img}
              <h3 className='h4'>{title}</h3>

              <section
                className='lead'
                dangerouslySetInnerHTML={{
                  __html: `${description}`,
                }}
              />
            </Card>
          </div>
        ))}
      </div>
    </>
  )
}

export default MailHandlingCard
