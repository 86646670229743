import React, { PropsWithChildren, ReactNode } from 'react'
import Card, { CardType } from './Card'
import { ReactFC } from 'common/types/ReactFC'
import classNames from 'classnames'
import HeadingWithSpan from 'components/HeadingWithSpan'
import Strings from 'project/localisation'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type TitleSubImageCardType = CardType & {
  footer?: ReactNode
  titleClass?: string
  robotHeadScale?: boolean
  cardOption?: number
}

const TitleSubImageCard: ReactFC<PropsWithChildren<TitleSubImageCardType>> = ({
  cardOption = 1,
  children,
  footer,
  robotHeadScale = false,
  titleClass,
  ...rest
}) => {
  const size = useWindowSize()
  return (
    <div className='col-lg-6 mb-lg-0 mb-4'>
      <Card
        {...rest}
        theme={rest.theme || 'gradient-purple'}
        padding={rest.padding || '0'}
        className={classNames(
          'text-center',
          'robot-head-card overflow-hidden',
          rest.className,
        )}
      >
        {cardOption === 1 && (
          <img
            loading='lazy'
            className={classNames(
              'img-fluid robot-head start-0 end-0 w-100',
              `robot-head--option${cardOption}`,
              robotHeadScale ? 'robot-head--scale' : '',
            )}
            alt={'Robot Head'}
            src={imageResize(
              require('public/images/banking/collect-page-10-mins-plugz-head-desktop.png'),
            )}
          />
        )}

        {cardOption === 2 && (
          <img
            loading='lazy'
            className={classNames('img-fluid robot-head')}
            alt={'Robot Head'}
            src={imageResize(
              require('public/images/banking/forward-page-10-mins-thumbs-up-desktop.png'),
            )}
            width={
              size.isXxxlOrHigher
                ? 675
                : size.isXlOrHigher
                ? 600
                : !size.isSmOrLower
                ? 550
                : 330
            }
          />
        )}

        <HeadingWithSpan
          size={3}
          className={classNames(
            titleClass,
            'text-center text-bold color-white position-relative',
          )}
        >
          {children}
        </HeadingWithSpan>
        {cardOption === 2 && (
          <p
            className='display-1 text-bold z-index-1 text-start mt-sm-3 ms-xl-n11 ms-sm-0 ms-n4'
            dangerouslySetInnerHTML={{ __html: `${Strings.minutesOrLess2}` }}
          />
        )}
        {footer && <div className='robot-head-card-footer'>{footer}</div>}
      </Card>
    </div>
  )
}

export default TitleSubImageCard
