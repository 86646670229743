import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultContent = {
  addressServicesDescription:
    '<p class="text-semibold mb-md-5 mb-3">The Hoxton Mix Virtual Office London gives you all the benefits of a prime London business address in the heart of London’s dynamic technology hub without the expensive overheads.</p><p class="mb-0">Use as your registered address with Companies House and keep your home address private with our Directors Service Address add-on.</p>',
  addressServicesForBusiness: 'Address services for business',
  icon: 'Icon',
}
export const addressServicesStrings = getApi().makeStrings({
  en: defaultContent,
  es: blobbyStrings(defaultContent),
})
