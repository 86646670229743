import React from 'react'
import Strings from 'project/localisation'
import ImageCard from './base/ImageCard'
import { ReactFC } from 'common/types/ReactFC'
import useWindowSize from 'components/util/useWindowSize'
import imageResize from 'project/image-proxy-web'

type VirtualOfficePlansCardType = {
  priority?: boolean
}

const VirtualOfficePlansCard: ReactFC<VirtualOfficePlansCardType> = ({
  priority,
}) => {
  const size = useWindowSize()
  return (
    <ImageCard
      priority={priority}
      gutters='g-0'
      padding='0'
      textAlign='center'
      verticalAlign='center'
      imageAlt={`${Strings.virtualOfficePlans} icon`}
      image={require('public/images/plans/homepage-choosing-plan-desktop.png')}
      className='px-xxl-14 px-sm-5 mt-md-12'
      classNameForCol1='col-lg-5 pt-xxxl-12 pb-xxxl-8 pt-8 pb-6'
      classNameForCol2='col-lg-7 d-lg-block d-none my-auto text-center'
    >
      <img
        loading={priority ? 'eager' : 'lazy'}
        className='img-fluid mb-4 envelope-icon'
        width={84}
        height={84}
        alt={'Envelope Icon'}
        src={imageResize(
          require('public/images/icons/homepage-vo-envelope-icon.svg'),
        )}
      />

      <span className='plan-text color-pink2 mb-lg-5 mb-1'>
        {Strings.virtualOfficePlans}
      </span>
      <h2
        className='text-semibold h2 mb-3'
        dangerouslySetInnerHTML={{
          __html: `${
            size.isLgOrHigher
              ? Strings.choosingTheRightPlan
              : Strings.choosingTheRightPlanForMobile
          }`,
        }}
      />
      {!size.isLgOrHigher && (
        <img
          loading={priority ? 'eager' : 'lazy'}
          className='img-fluid max-w-lg-sm-50'
          alt={`${Strings.virtualOfficePlans} icon`}
          src={imageResize(
            require('public/images/plans/homepage-choosing-plan-desktop.png'),
          )}
        />
      )}
      <p
        className='lead px-sm-3 px-5'
        dangerouslySetInnerHTML={{
          __html: `${Strings.choosingTheRightPlanDescription}`,
        }}
      />
      <p
        className='lead-large-desktop text-semibold'
        dangerouslySetInnerHTML={{
          __html: `${Strings.idealFor}`,
        }}
      />
    </ImageCard>
  )
}

export default VirtualOfficePlansCard
