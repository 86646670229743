import ImageCard from './base/ImageCard'
import Strings from 'project/localisation'
import Multiline from 'components/util/Multiline'
import Button from 'components/forms/Button'
import { Constants } from 'common/utils'
import { ReactFC } from 'common/types/ReactFC'
import Heading from 'components/Heading'
import Link from 'next/link'
import classNames from 'classnames'
import useWindowSize from 'components/util/useWindowSize'

type OurAddressCardType = {
  className?: string
}

const OurAddressCard: ReactFC<OurAddressCardType> = ({
  className = 'mt-lg-12 mt-5 mb-lg-20 mb-8',
}) => {
  const size = useWindowSize()
  return (
    <ImageCard
      gutters='g-0'
      textAlign='center'
      verticalAlign='center'
      imageAlt={`${Strings.ourAddress} icon`}
      image={require('public/images/address/homepage-plugz-point-desktop.png')}
      mobileImageProps={{ className: 'w-100 px-3 max-w-lg-sm-50' }}
      mobileImage={require('public/images/address/homepage-plugz-point-mobile.png')}
      className={classNames(className, 'ps-xl-18 pe-xl-4 pt-lg-4')}
      classNameForCol1='col-lg-4 py-md-6 pt-7 pb-0'
      classNameForCol2='col-lg-8 my-auto'
    >
      <Heading size={2} className='h2 text-semibold mb-4'>
        {Strings.ourAddress}
      </Heading>
      <p className='lead-large text-semibold mb-md-5 mb-3'>
        <Multiline
          text={`${Constants.companyInformation.address2}, ${Constants.companyInformation.address3},\n${Constants.companyInformation.city},${Constants.companyInformation.postal_code}`}
        />
        <a
          className={classNames(
            'text-link-pink',
            size.isMdOrLower ? 'text-underline' : '',
          )}
          href='https://g.page/HoxtonMix?share'
          target='_blank'
          rel={'noopener noreferrer'}
        >
          {Strings.directions}
        </a>
      </p>
      <p className='lead-large text-semibold mb-sm-3 mb-2'>
        {Strings.openingHours}
      </p>
      <p className='lead-large mx-sm-0 mx-n5'>
        {Strings.openingHoursDescription}
      </p>
      <Link className='a-unstyled' href={'/contact-us'}>
        <Button className='mt-md-3 mt-2 px-7' theme='secondary'>
          {Strings.contactUs}
        </Button>
      </Link>
    </ImageCard>
  )
}

export default OurAddressCard
