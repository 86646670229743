import React from 'react'
import 'project/polyfill'
import { NextPageWithLayout } from '../types/nextPageWithLayout'
import { I18NConfig } from 'next/dist/server/config-shared'
import { createClient } from '../prismicio'
import { getPosts, getReviews } from 'common/cms-sdk'
import { getPlans } from 'common/planData'
import { Utils } from 'common/utils'
import SeoContainer, {
  SeoContainerProps,
} from 'components/util/containers/SeoContainer'
import Link from 'next/link'
import Strings from 'project/localisation'
import HeroHome from 'components/hero/HeroHome'
import Button from 'components/forms/Button'
import { TBlogPostResult, TPlanSummary, TReviews } from 'common/cms-types'
import PlanList from 'components/plans/PlanList'
import VirtualOfficePlansCard from 'components/cards/VirtualOfficePlansCard'
import PlansDesksOffices from 'components/PlansDesksOffices'
import TenMinutesOrLessCard from 'components/cards/TenMinutesOrLessCard'
import NoSetupFeesCard from 'components/cards/NoSetupFeesCard'
import MailHandlingCard from 'components/cards/MailHandlingCard'
import AddressServicesCard from 'components/cards/AddressServicesCard'
import ReviewsTrustCard from 'components/cards/ReviewsTrustCard'
import CompanyFormationCard from 'components/cards/CompanyFormationCard'
import OfficeSection from 'components/sections/OfficeSection'
import OurAddressCard from 'components/cards/OurAddressCard'
import { Query } from '@prismicio/types'
import { seoStrings } from 'common/strings/seo-strings'
import ReviewsSection from 'components/sections/ReviewsSection'
import { homeBreadcrumb } from 'components/util/containers/Breadcrumbs'
import GetInTouchSection from 'components/sections/GetInTouchSection'
import HeadingAndText from 'components/HeadingAndText'
import BlogSection from 'components/sections/BlogSection'
import WhatHowWhyCards from 'components/cards/WhatHowWhyCards'

export type HomePageType = {
  posts: Query<TBlogPostResult>
  plans: TPlanSummary[]
  reviews: TReviews
}

const HomePage: NextPageWithLayout<HomePageType> = ({
  plans,
  posts,
  reviews,
}) => {
  const seoProps: SeoContainerProps['seoProps'] = {
    description: seoStrings.indexDescription,
    openGraph: {
      description: seoStrings.indexOGDescription,
      url: 'https://www.hoxtonmix.com/',
    },
    title: seoStrings.indexTitle,
  }
  return (
    <SeoContainer breadcrumb={homeBreadcrumb()} seoProps={seoProps}>
      <main className={'overflow-x-hidden'}>
        <HeroHome
          className='hero--home'
          logoColor='white'
          title={Strings.heroTitle}
          image={require('public/images/hero/homepage-collect-plan-desktop.png')}
          subtitle={`${Strings.heroSubtitle}`}
          background='pink'
          cta={
            <div className='my-sm-5 mt-2 mb-3'>
              <Link
                className='a-unstyled'
                href='/virtual-office-london-old-street'
              >
                <Button
                  className='px-6 px-md-7 position-relative z-index-1'
                  theme='secondary'
                >
                  {Strings.viewPlans}
                </Button>
              </Link>
            </div>
          }
          isRightCTA
        />
        <div className='container'>
          <WhatHowWhyCards />
          <VirtualOfficePlansCard priority />
          <PlanList className='mt-sm-5 mt-3' plans={plans} />
          <HeadingAndText
            className='mt-xl-17 mt-lg-10 mt-7 mb-xl-11 mb-lg-10 mb-7 px-sm-0 px-4'
            title={Strings.helpBusinessesGrow}
            subtitle={Strings.helpBusinessesGrowDescription}
          />
          <div className='row'>
            <AddressServicesCard />
            <ReviewsTrustCard />
          </div>
          <PlansDesksOffices />
          <div className='mb-lg-5 mb-4'>
            <TenMinutesOrLessCard />
          </div>
          <div className='row mb-4'>
            <NoSetupFeesCard />
            <MailHandlingCard />
          </div>
          <HeadingAndText
            className='mt-xl-17 mt-lg-10 mt-7 mb-xl-11 mb-lg-10 mb-8'
            title={Strings.freeCompanyFormations}
            subtitle={Strings.freeCompanyFormationsDescription}
            headingSize={3}
          />
          <CompanyFormationCard />
          <GetInTouchSection />
          <OfficeSection />
          <OurAddressCard />
        </div>
        <BlogSection posts={posts} />
        <ReviewsSection data={reviews} />
      </main>
    </SeoContainer>
  )
}
const PAGE_SIZE = 4

export async function getStaticProps(
  context: I18NConfig & { locale?: string; params: { uid: string } },
) {
  try {
    const client = createClient(context)
    const lang = Utils.getLocaleID(context.locale || context.defaultLocale)
    const plans = await getPlans()
    const posts = await getPosts(client, 1, lang, PAGE_SIZE, undefined)
    const reviews = await getReviews()
    return {
      props: {
        plans,
        posts,
        reviews,
      },
    }
  } catch (e) {
    console.log(e)
    return {
      props: {},
    }
  }
}

export default HomePage
